<template>
	<div :style="bg">
		<Header :title="$t('money.transfer_title')" ></Header>
		
		<div class="column-center-content main-content">
			<div class="row-content" style="background-color: rgb(231,231,231); height: 40px; width: 90%; padding: 5px 16px; margin-bottom: 10px;">
				<label style="color: #FF0000; font-weight: bold;">{{$t('money.name')}}:</label>
				<label style="color: #FF0000; margin-left: 5px;" v-text="name"></label>
			</div>
			
			<div class="form-content">
				<label class="form-title">{{$t('assets.target')}}</label>
				<v-text-field :placeholder="$t('assets.target_hint')" color="success" style="width: 100%; padding-top: 5px;" v-model="targetUser"></v-text-field>
			</div>
			
			<div class="form-content">
				<label class="form-title">{{$t('fund.amount')}}</label>
				<div class="row-content" style="width: 100%;">
					<v-text-field disabled type="number" :placeholder="$t('fund.amount_hint')" color="success" style="width: 100%; padding-top: 5px;" v-model="amount"></v-text-field>
					<label style="line-height: 40px; width: 50px; text-align: right;">USDT</label>
				</div>
			</div>
			
			<div class="form-content">
				<label class="form-title">{{$t('assets.payPwd')}}</label>
				<v-text-field :placeholder="$t('assets.payPwd_hint')" color="success" style="width: 100%; padding-top: 5px;" v-model="pwd" type="password"></v-text-field>
			</div>
			
			<div style="padding: 10px 16px; color: #888888; font-size: 14px; text-align: justify; width: 100%;">
				<label>
					<span style="font-size: 16px;">{{$t('common.safeTitle')}}：<br/></span>
					{{$t('transfer.tips1')}}<br/>
					{{$t('transfer.tips2')}}<br/>
					{{$t('transfer.tips3')}}<br/>
				</label>
			</div>
			
			<div class="btn-content">
				<v-btn rounded block @click="showConfirmTip" style="background-color: #009245; color: white; height: 40px;">
					{{$t('common.submit')}}
				</v-btn>
			</div>
		</div>
		
		<mt-popup style="max-width: 320px; width: 80%; align-items: center; border-radius: 10px;" v-model="confirmTipVisible" popup-transition="popup-fade">
			<TransferConfirmTip :tipInfo="tipInfo"></TransferConfirmTip>
		</mt-popup>
		
		<mt-popup style="max-width: 320px; width: 80%; align-items: center; border-radius: 10px;" v-model="successTipVisible" popup-transition="popup-fade">
			<TransferSuccessTip :tipInfo="tipInfo"></TransferSuccessTip>
		</mt-popup>
	</div>
</template>

<script>
	import "@/styles/common.css"
	import { Indicator } from 'mint-ui';
	import { Toast } from 'mint-ui';
	import Header from '../../components/NavHeader.vue'
	import TransferConfirmTip from '../../components/TransferFundConfirmTip.vue'
	import TransferSuccessTip from '../../components/TransferFundSuccessTip.vue'
	
	export default {
		name: 'TransferFund',
		components: {
			Header,
			TransferConfirmTip,
			TransferSuccessTip
		},
		created() {
			this.fund = this.$route.params
			this.amount = this.fund.cost
		},
		data() { 
			return {
				bg : {
					height: "100vh",
					width: "100%",
					backgroundImage: "url(" + require("../../assets/login/new-bg.png") + ")",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "fixed",
					backgroundSize: "100% 100%",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					overflow: "auto"
				},
				fund: {},
				gauth: localStorage.getItem('gauth'),
				name: '非洲矿业基金',
				targetUser: '',
				targetName: '',
				targetPhone: '',
				amount: '',
				pwd: '',
				confirmTipVisible: false,
				successTipVisible: false,
				tipInfo: {
					title: '',
					name: '',
					amount: 0,
					from: '',
					to: '',
					targetName: '',
					targetPhone: ''
				}
			}
		},
		methods: {
			showConfirmTip() {
				
				let target = this.$data.targetUser;
				let amount = this.$data.amount;
				let pwd = this.$data.pwd;
				
				if(null == target || '' == target) {
					Toast({
						message: this.$i18n.t("assets.target_hint"),
						position: 'bottom'
					});
					return;
				}
				
				if(null == amount || '' == amount) {
					Toast({
						message: this.$i18n.t("fund.amount_hint"),
						position: 'bottom'
					});
					return;
				}
				
				if(amount <= 0) {
					Toast({
						message: this.$i18n.t("fund.amount_error"),
						position: 'bottom'
					});
					return;
				}
				
				if(null == pwd || '' == pwd) {
					Toast({
						message: this.$i18n.t("assets.payPwd_hint"),
						position: 'bottom'
					});
					return;
				}
				
				// 请求接收用户的信息
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				this.$request.getTargetInfo({
					account: target
				}).then((data)=>{
					this.$data.targetName = data.name;
					this.$data.targetPhone = data.phone;
					Indicator.close();
					this.$data.tipInfo = {
						title: 'money.transfer_title',
						name: '非洲矿业基金',
						amount: amount,
						from: JSON.parse(localStorage.getItem('userInfo')).account,
						to: target,
						targetName: data.name,
						targetPhone: data.phone
					}
					// 弹窗提示确认互转信息
					this.$data.confirmTipVisible = true;
				});
			},
			
			closePop() {
				this.$data.confirmTipVisible = false;
				this.$data.successTipVisible = false;
			},
			
			transfer() {
				this.$data.confirmTipVisible = false;
				
				let target = this.$data.targetUser;
				let pwd = this.$data.pwd;				
				let params = {
					fundId: this.$data.fund.id,
					payPasswd: pwd,
					target: target
				}
				Indicator.open({
					text: '',
					spinnerType: 'fading-circle'
				});
				
				this.$request.exchangeFund(
					params
				).then(()=>{
					Indicator.close();
					sessionStorage.setItem('refreshInfo', '1');
					this.$data.tipInfo = {
						title: 'money.transfer_title',
						name: '非洲矿业基金',
						amount: this.$data.amount,
						from: JSON.parse(localStorage.getItem('userInfo')).account,
						to: this.$data.targetUser,
						targetName: this.$data.targetName,
						targetPhone: this.$data.targetPhone
					}
					// 互转成功，展示成功弹窗
					this.$data.successTipVisible = true;					
				});
			},
			
			goBack() {
				this.$router.push({
					"name": "Main"
				})
			}
		}
	}
</script>

<style>
</style>
